<template>
<div>
	x: {{kip}}
	<template v-for="(child, index) in children">
  	    <component :is="child" :key="child.name"></component>
  	</template>

  	<button @click="addField()">Add Another</button>


	<q-form v-if="showAdd||showEdit" class="shadow-1 q-pa-md">
		<span class="form-title">{{title}}</span>

		<q-input
        filled
        v-model="name"
        label="Name"
        hint="Members name"
        lazy-rules
        :rules="[ val => val && val.length > 0 || 'Please type something']"/>

		<q-input
        filled
        v-model="login"
        label="Login"
        hint="Login"
        lazy-rules
        :rules="[ val => val && val.length > 0 || 'Please type something']"/>


		<q-input
        filled
        v-model="email"
        label="Email"
        hint="Email"
        lazy-rules
        :rules="[ val => val && val.length > 0 || 'Please type something']"/>


        <br/>


		<div class="q-mt-md flex flex-center">
			<q-btn flat label="Cancel" @click="cancel" color="secondary"/>
			<q-btn flat label="Save" @click="save" color="primary"/>
		</div>


	</q-form>



	<section v-if="!showAdd && !showEdit">

		<q-table
      		:data="members"
      		:columns="columns"
      		row-key="uuid"
      		:pagination="{rowsPerPage:20}"
      		:dense="true"
      		:filter="filter"

    	>

        <template v-slot:top-right="props">
        	<q-input
              outlined
              dense
              debounce="300"
              v-model="filter"
              placeholder="Search"
            >
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
		</template>

         <template v-slot:body-cell-actions="props">
            <q-td :props="props">
              <q-btn dense round flat color="grey" @click="edit(props)" icon="fad fa-pencil"></q-btn>
              <q-btn dense round flat color="grey" @click="remove(props)" icon="fad fa-trash"></q-btn>
            </q-td>
          </template>

    	</q-table>


	</section>



</div>
</template>
<script>
import Vue from 'vue'
import { API, clone, humanDate } from '../tic'
import { QSpinnerGears, QInput } from 'quasar'

// simple example: https://codepen.io/glikoz/pen/qYEZEV?editors=1010
// https://levelup.gitconnected.com/add-dynamic-forms-to-your-vue-app-609de4005a2b
// https://forum.quasar-framework.org/topic/930/need-quasar-form-components-to-emit-on-change-input/7

let Field = {
	template: `<q-input filled v-model="login" label="Login"/>`
}

let xRend = new Vue({
	template: `<q-input filled v-model="login" label="Login"/>`,
	render: (h) => {
		return h('h1', 'Hello')
		//return h('<img src="https://paulj.home.xs4all.nl/trein.jpg"/>')
	}
})


//             data.props.value = this.model[f.model] ? this.model[f.model] : data.props.value



let Rend = {
	props: ['value'],
	render(h) {
		let self = this
		console.log("this: ", this)
		return h('input', {
			domProps: {
				value: self.value
			},

			on: {
				input: (v,e) => {
					console.log("input: ", v,e)
					self.$emit('input', v)
					//this.onInput(v, 'foo'
				}
			},

			model: 'foo',
			attrs: {
				placeholder: 'dit is de placehoxder'
			},

			props: {
				filled:true,
				//label:'label',
				hint:this.foo,
				value: 42
			}
		})
	},

	data() {
		return {
			foo: 'FOOBARx'
		}
	},

	// methods: {
	// 	onInput(value, field) {
	// 		console.log("input!: field", field, " val: ", value)
 //         	this.$set(field, value)
 //        }
	// }

}

console.log(">>>>> ", typeof Rend)
console.log(">>>>> ", new Vue())


export default {
	name: 'admin-groups',
	props: ['showAdd'],
	data() {
		return {
			kip: '',
			children: [],
			filter: '',
			showEdit: false,
			archetypes: [],
			actions: [],
			cleanPerms: {},
			perms: {},
			permset: {},
			name: '',
			code: '',
			members: [],
			columns: [
				{ name: 'name', label:'name', field: row => row.name, align:'left', sortable: true },
				{ name: 'login', label:'login', field: row => row.login, align:'left' },
				{ name: 'email', label:'email', field: row => row.email, align:'left' },
				{ name: 'joined', label:'joined', field: row => row.joined, format: row => humanDate(row), sortable: true, align:'left' },
				{ name: 'actions', label:'action', field: "actions", align:'right' },

			]
		}
	},
	computed: {
		title: function() {
			let a = this.showAdd ? 'Add' : 'Edit'
			return `${a} a member`
		}
	},

	mounted () {
		// get all members
		this.load()
	},

	methods: {
		addField() {
			console.log("add a component: ", Rend)
			this.children.push(Rend);
		},

		load() {
			let vm = this
			// load the groups data
			API.get('/system/members').then(r => {
				vm.members = r.data.data
			})
		},

		cancel() {
			this.showEdit=false
			this.$emit('hide')
		},

		edit(props) {
			let record = this.groups.find(e => e.uuid===props.key)
			this.code = record.code
			this.name = record.name
			this.uuid = record.uuid
			this.perms = record.perms ? record.perms : this.cleanPerms
			this.showEdit=true
		},

		remove(props) {
			this.$q.dialog({
        		title: '<h4 class="confirm"><i class="fad fa-trash"></i>&nbsp;Confirm</h4>',
        		message: `Really delete this item?<br><b>${props.row.name}</b>`,
        		cancel: true,
        		persistent: true,
        		html: true
      		}).onOk(() => {
	          	API.delete(`/system/member/${props.key}`).then(r => {
	          		// reload data after delete
					this.load()
				})
			})
		},

		save () {
			this.$q.loading.show({
				spinner: QSpinnerGears,
          		spinnerColor: 'green',
          		messageColor: 'green',
          		backgroundColor: 'lightgrey'
          	})

          	API.post('/system/group', {
          		code:this.code,
          		name:this.name,
          		perms:this.perms,
          		uuid:this.uuid ? this.uuid:null

          	}).then(r => {
          		this.$q.loading.hide()
          		this.$emit('hide')
          		this.showEdit=false
          		this.code=''
          		this.name=''
          		this.uuid=null
          		this.perms=clone(this.cleanPerms)
          		// reload the data
          		this.load()
          	})
		},

		getCheckboxValue(type, perm) {
			return this.perms[type][perm]
		},

		checkboxClick(value, type, perm) {
			this.perms[type][perm] = !this.perms[type][perm]
			// force an update, Vue is not smart enough to detect the mutation above.
			this.$forceUpdate();

		}
    }

}
</script>
<style>

tbody.permissions td:nth-child(even) {
  background-color: #eee;
}

</style>